import { Background } from "@appcharge/shared-ui";
import Footer from "../footer/footer";
import { MessagePageProps } from "./message-page.types";
import { useSelector } from "react-redux";
import { ThemeSliceState } from "../../store/store.types";
import "./style.scss";

const MessagePage = ({
  headline,
  children,
  images,
  lines,
  footerLinks,
}: MessagePageProps) => {
  const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
  return (
    <Background
      backgroundImageMobile={theme.general.backgroundImageMobile}
      backgroundImageDesktop={theme.general.backgroundImageDesktop}
    >
      <div
        id='static'
        style={{
          fontFamily: theme.general.font,
        }}
      >
        {images?.map((img, i) => (
          <img key={i} alt={img.class} className={img.class} src={img.src} />
        ))}
        <Footer links={footerLinks} />
      </div>
    </Background>
  );
};

export default MessagePage;
