// import Modal from '../Modal/Modal';
import { LogoutModalProps } from './LogoutModal.types';
import { ActionButton, Modal } from '@appcharge/shared-ui';
import { useSelector } from "react-redux";
import { ThemeSliceState } from "../../store/store.types";

const LogoutModal = ({closePopup, logout}: LogoutModalProps) => {
    const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
    const logoutHandler = () => {
        closePopup();
        logout();
    }
    return (
        <Modal
            closeModal={closePopup}
            title="Logout"
            titleColor={theme.paymentModal.headerColor}
            titleSize={theme.paymentModal.headerSize}
            subtitle="Are you sure you want to disconnect?"
            subtitleColor={theme.paymentModal.textColor}
            subtitleSize={theme.paymentModal.textSize}
            fontFamily={theme.general.font}
            style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
            }}
            modalColor={theme.paymentModal.popupColor}
            modalBorderColor={theme.paymentModal.popupBorderColor}
        >
            <ActionButton text='Logout' action={logoutHandler} colors={theme.general.buttonColor}/>
        </Modal>
    );
};

export default LogoutModal;
