export enum GradientDirection {
    TopToBottom = "to bottom",
    BottomToTop = "to top",
    LeftToRight = "to right",
    RightToLeft = "to left",
    TopLeftToBottomRight = "to bottom right",
    BottomRightToTopLeft = "to top left",
    TopRightToBottomLeft = "to bottom left",
    BottomLeftToTopRight = "to top right",
}

export enum FontWeight {
    Bold = "bold",
    Normal = "normal",
    Bolder = "bolder"
}
export interface DesignSliceState {
    bundles: string;
}

export interface UserSliceState {
    facebookId: string,
    profilePicture: string,
    braintreeId: string
}

interface gradient {
    colorOne: string;
    colorTwo?: string;
    gradientDirection?: GradientDirection;
}
export interface generalTheme {
    backgroundImageMobile: string;
    backgroundImageDesktop: string;
    buttonColor: gradient;
    buttonTextColor: string;
    font: string;
    fontWeightForText: string;
    footerImageMobile: string;
    footerImageWeb: string;
    logo: string;
}

export interface loginTheme {
    text: string;
    textColor: string;
    textSize: number;
    textWeight: "normal" | "bold" | "bolder";
}

export interface storeTheme {
    borderSize: number;
    bundleBorderColor: gradient;
}

export interface paymentModalTheme {
    headerText: string;
    headerColor: gradient;
    headerSize: number;
    text: string;
    textColor: string;
    textSize: number;
    popupColor: gradient;
    popupBorderColor: gradient;
}

export interface completedScreenTheme {
    backToStoreText: string;
    headerColor: gradient;
    headerSize: number;
    headerText: string;
    text: string;
    textColor: string;
    textSize: number;
}

export interface loaderScreenTheme {
    gif: string;
    headerColor: gradient;
    headerSize: number;
    headerText: string;
    text: string;
    textColor: string;
    textSize: number;
}

export interface ThemeSliceState {
    general: generalTheme;
    login: loginTheme;
    storeScreen: storeTheme;
    paymentModal: paymentModalTheme;
    completedScreen: completedScreenTheme;
    loaderScreen: loaderScreenTheme;
}

export interface IntegrationSliceState {
    fbAppId: string;
    publisherId: string;
    backToGameDeepLink: string;
}
