import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user-slice";
import designSlice from "./design-slice";
import themeSlice from "./theme-slice";
import integrationSlice from "./integration-slice";

const store = configureStore({
    reducer: {
        "user": userSlice,
        "design": designSlice,
        "theme": themeSlice,
        "integration": integrationSlice
    }
})

export default store;
