import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 100;
  color: white;
  gap: 1em;
`;

const StyledProcessingText = styled.span`
  color: white;
  font-size: 1.5em;
  font-family: Arial, Helvetica, sans-serif;
`;

const Spinner = () => {
  return (
    <StyledContainer>
      <CircularProgress
        sx={{
          color: "white",
        }}
      />
      <StyledProcessingText>Processing</StyledProcessingText>
    </StyledContainer>
  );
};

export default Spinner;
