import { MessagePage, Background, ActionButton } from '@appcharge/shared-ui';
import useUrlQuery from "../../hooks/useUrlQuery";
import { useNavigate } from "react-router-dom";
import { errorCodes } from "./failed.types";
import { useSelector } from "react-redux";
import { ThemeSliceState } from "../../store/store.types";

const Failed = () => {
  const error = useUrlQuery("error") as errorCodes;
  const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
  const navigate = useNavigate();
  
  const dic = {
    headline: {
      default: "Oh No!",
      payment: "Oh No!",
      auth: "Whoops!"
    },
    lines: {
      default: "Something went wrong",
      payment: "Your payment was successful, but there's an issue with the order.",
      auth: "Seems like you're unable to access the store"
    }
  }

  const returnToShop = () => {
    navigate('../');
  }

  return (
    <Background
      backgroundImageDesktop={theme.general.backgroundImageDesktop}
      backgroundImageMobile={theme.general.backgroundImageMobile}
    >
      <MessagePage
        headerText={error ? dic.headline[error] : dic.headline.default}
        headerColor={theme.completedScreen.headerColor}
        headerSize={theme.completedScreen.headerSize}
        text={error ? dic.lines[error] : dic.lines.default}
        textColor={theme.completedScreen.textColor}
        textSize={theme.completedScreen.textSize}
        fontFamily={theme.general.font}
      >
        <ActionButton action={returnToShop} text="Return to shop" colors={theme.general.buttonColor}/>
      </MessagePage>
    </Background>
  );
};

export default Failed;
