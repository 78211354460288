import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IntegrationSliceState } from './store.types';

const initialIntegrationSliceState: IntegrationSliceState = {
    fbAppId: "",
    backToGameDeepLink: "",
    publisherId: ""
};

const integrationSlice = createSlice({
    name: 'integration',
    initialState: initialIntegrationSliceState,
    reducers: {
        setupIntegration: (state: IntegrationSliceState, action: PayloadAction<Partial<IntegrationSliceState>>) => {
            state.fbAppId = action.payload.fbAppId!;
            state.backToGameDeepLink = action.payload.backToGameDeepLink!;
            state.publisherId = action.payload.publisherId!;
        }
    }
});

export const integrationActions = integrationSlice.actions;
export default integrationSlice.reducer;
