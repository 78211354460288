import MessagePage from "../../components/message-page/message-page";

const Maintenance = () => {
    const dic = {
        headline: "Under Construction",
        lines: [
          "We're currently under maintenance, we will be up and running soon!",
        ],
        images: [
            {
                src: `${process.env.PUBLIC_URL}/assets/images/giraff.png`,
                class: "giraff"
            }
        ]
    }
    return (
        <MessagePage 
            headline={dic.headline}
            lines={dic.lines}
            footerLinks={false}
            images={dic.images}
            returnButton={true}
        />
    );
};
  
export default Maintenance;
