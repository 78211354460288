import { BrowserRouter } from 'react-router-dom';
import { Provider }      from 'react-redux';
import ReactDOM          from 'react-dom/client';
import store             from './store/index';
import App               from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);
