import { createSlice } from "@reduxjs/toolkit";
import { GradientDirection, ThemeSliceState, FontWeight } from "./store.types";

const initialThemeSliceState: ThemeSliceState = {
  general: {
    logo: "",
    backgroundImageMobile: "",
    backgroundImageDesktop: "",
    footerImageMobile: "",
    footerImageWeb: "",
    font: "",
    fontWeightForText: "",
    buttonColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    buttonTextColor: "",
  },
  login: {
    text: "",
    textColor: "",
    textSize: 0,
    textWeight: FontWeight.Normal,
  },
  storeScreen: {
    bundleBorderColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    borderSize: 0,
  },
  paymentModal: {
    headerText: "",
    headerColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    headerSize: 0,
    text: "",
    textColor: "",
    textSize: 0,
    popupColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    popupBorderColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
  },
  loaderScreen: {
    headerText: "",
    headerColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    headerSize: 0,
    text: "",
    textColor: "",
    textSize: 0,
    gif: "",
  },
  completedScreen: {
    headerText: "",
    headerColor: {
      colorOne: "",
      colorTwo: "",
      gradientDirection: GradientDirection.LeftToRight,
    },
    headerSize: 0,
    text: "",
    textColor: "",
    textSize: 0,
    backToStoreText: "",
  },
};

const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeSliceState,
  reducers: {
    setTheme(state: ThemeSliceState, action) {
      state.login = action.payload.login;
      state.general = action.payload.general;
      state.storeScreen = action.payload.storeScreen;
      state.paymentModal = action.payload.paymentModal;
      state.loaderScreen = action.payload.loaderScreen;
      state.completedScreen = action.payload.completedScreen;
    },
  },
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
