export const CONF = {
  development: {
    api: process.env.REACT_APP_API_BASE_URL_DEV,
    facebook: process.env.REACT_APP_FACEBOOK_APP_ID_DEV,
    googleAnalytics: process.env.REACT_APP_GA_TRACKING_ID_DEV
  },
  production: {
    api: process.env.REACT_APP_API_BASE_URL_PROD,
    facebook: process.env.REACT_APP_FACEBOOK_APP_ID_PROD,
    googleAnalytics: process.env.REACT_APP_GA_TRACKING_ID_PROD
  },
  staging: {
    api: process.env.REACT_APP_API_BASE_URL_STAGING,
    facebook: process.env.REACT_APP_FACEBOOK_APP_ID_STAGING,
    googleAnalytics: process.env.REACT_APP_GA_TRACKING_ID_STAGING
  }
};
