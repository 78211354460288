
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { CookieConcentProps } from './cookie-consent.types';
import './style.scss';

const CookieConsent = ({setShowCookieOverlay}: CookieConcentProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [enableAnalytics, setEnableAnalytics] = useState(true);

    const openDialog = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setShowDialog(true);
    }

    const saveSettings = () => {
        localStorage.setItem("analytics", JSON.stringify(enableAnalytics));
        setShowCookieOverlay(false);
    }

    const closeDialog = () => {
        setShowDialog(false);
    }

    const toggleAnalytics = () => {
        setEnableAnalytics(!enableAnalytics);
    }

    return (
        <div id="cookieConesnt" onClick={closeDialog}>
            {
                showDialog &&
                <div className='dialog' onClick={(event) => event.stopPropagation()}>
                    <Typography variant='h3'>Cookies Settings</Typography>
                    <div>
                        <div className='header'>
                            <Typography variant='h4'>Strictly Necessary Cookies</Typography>
                            <label className="switch disabled">
                                <input type="checkbox" checked />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <Typography variant='body1'>These cookies enable core functionality such as security, network management, and accessibility. They are essential for the proper functioning of the website.</Typography>
                    </div>
                    <div>
                        <div className='header'>
                            <Typography variant='h4'>Analytics Cookies</Typography>
                            <label className="switch">
                                <input type="checkbox" checked={enableAnalytics} onChange={toggleAnalytics} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <Typography variant='body1'>These cookies help us optimize your experience on our website and make it more enjoyable for you.</Typography>
                    </div>
                    <button onClick={saveSettings}>Save settings</button>
                </div>
            }
            <div className='banner'>
                <Typography variant='subtitle1' component='p' align='center'>
                    We use cookies to save information on your device. Some help make this site work, others to enhance your experience! You can accept all cookies or visit cookie settings to adjust your preference.
                    See our <a href='./privacy'>privacy policy</a> for more.
                </Typography>
                <div>
                    <button className='adjust' onClick={openDialog}>Adjust cookies</button>
                    <button className='accept' onClick={saveSettings}>Accept all</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
