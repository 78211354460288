import { useQuery } from 'react-query';
import { EQueryKeys } from '../../../constants/query-keys';
import useAxios from '../../../hooks/useAxios';

export default function usePaypal(paypalInstace: braintree.PayPal | undefined){
    const axios = useAxios();
    return useQuery({
        queryKey: EQueryKeys.PAYPAL,
        retry: false,
        queryFn: async () => {
            if(paypalInstace) return;
            return await axios.get('/store/paypal/client-token').catch(err => {
                throw err;
            })
        },
    })
}
