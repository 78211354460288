import { userActions } from "../../store/user-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Background } from "@appcharge/shared-ui";
import { Login } from "@appcharge/shared-ui";
import CookieConsent from "../../components/cookie-consent/cookie-consent";
import useUrlQuery from "../../hooks/useUrlQuery";
import { useEffect } from "react";
import { fbLoginResponse } from "./login.types";
import Spinner from "../../components/Spinner/Spinner";
import { localStorageUtil } from "../../utils";
import { ELocalStorageKeys } from "../../constants/enums";
import { LoginProps } from "./login.types";
import { useSelector } from "react-redux";
import {
  ThemeSliceState,
  IntegrationSliceState,
} from "../../store/store.types";
import "./style.scss";

const LoginPage = ({ initAnalytics }: LoginProps) => {
  const axios = useAxios();
  const utmSource = useUrlQuery("utm_source");
  const [showCookieOverlay, setShowCookieOverlay] = useState(
    localStorage.getItem("analytics") === null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
  const integration = useSelector(
    ({ integration }: { integration: IntegrationSliceState }) => integration
  );
  const [processing, setProcessing] = useState(false);
  const fbId = localStorageUtil.get(ELocalStorageKeys.FB_ID);
  const fbProfilePic = localStorageUtil.get(ELocalStorageKeys.FB_PROFILE_PIC);

  useEffect(() => {
    if (fbId) {
      dispatch(
        userActions.login({
          facebookId: fbId,
          profilePicture: fbProfilePic,
        })
      );
      navigate(`/${utmSource ? `?utm_source=${utmSource}` : ""}`);
    }
  }, [fbId, navigate, dispatch, fbProfilePic, utmSource]);

  useEffect(() => {
    initAnalytics();
  }, [showCookieOverlay, initAnalytics]);

  const responseFacebook = async (response: fbLoginResponse) => {
    if (response.accessToken) {
      const apiAuthResponse = await axios.post("/auth/store/facebook", {
        data: {
          fbAppId: integration.fbAppId,
          fbUserToken: response.accessToken,
          publisherId: integration.publisherId,
          fbProfileImageUrl: response.picture.data.url,
        },
      } as any);
      if (apiAuthResponse.status === 201) {
        dispatch(
          userActions.login({
            facebookId: response.userID,
            profilePicture: response.picture.data.url,
          })
        );
        localStorageUtil.set(ELocalStorageKeys.FB_ID, response.userID);
        localStorageUtil.set(
          ELocalStorageKeys.FB_PROFILE_PIC,
          response.picture.data.url
        );
        navigate(`../${utmSource ? `?utm_source=${utmSource}` : ""}`);
      } else if (apiAuthResponse.response.status === 401)
        navigate("../failed?error=auth");
      else navigate("../failed");
    }
    setProcessing(false);
  };

  const facebookCLicked = (callback: Function) => {
    setProcessing(true);
    callback();
  };

  return (
    <>
      {processing && <Spinner />}
      <Background
        backgroundImageMobile={theme.general.backgroundImageMobile}
        backgroundImageDesktop={theme.general.backgroundImageDesktop}
      />
      {integration.fbAppId && (
        <Login
          appId={integration.fbAppId}
          text={theme.login.text}
          textColor={theme.login.textColor}
          textSize={theme.login.textSize}
          textWeight={theme.login.textWeight}
          logo={theme.general.logo}
          responseFacebook={responseFacebook}
          facebookClicked={facebookCLicked}
          fontFamily={theme.general.font}
        />
      )}
      {showCookieOverlay && (
        <CookieConsent setShowCookieOverlay={setShowCookieOverlay} />
      )}
    </>
  );
};

export default LoginPage;
