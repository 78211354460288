import axios from 'axios';
import { CONF } from '../conf';

axios.defaults.baseURL = (CONF as any)[process.env.REACT_APP_ENV!].api;
axios.defaults.withCredentials = true;

export default function useAxios(){
    const post = async (path: string, {data, headers}: {data: any, headers: any}) => {
        const response = await axios({
            url: path,
            method: 'POST',
            headers,
            data
        }).catch(err => {
            return err;
        });
        return response;
    }

    const get: (path: string) => Promise<any> = async (path) => {
        const response = await axios({
            url: path,
            method: "GET"
        });
        return response.data;
    }

    return {post, get};
}
