import { createSlice } from '@reduxjs/toolkit';
import { DesignSliceState } from './store.types';

const designSlice = createSlice({
    name: 'design',
    initialState: {
        bundles: "design2"
    } as DesignSliceState,
    reducers: {
        setDesign(state: DesignSliceState, action) {
            Object.keys(action.payload).forEach(componentName => {
                (state as any)[componentName] = action.payload[componentName];
            });
        }
    }
});

export const designActions = designSlice.actions;
export default designSlice.reducer;
