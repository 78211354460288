import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BundlesProps } from "./Bundles.types";
import { Bundle } from "@appcharge/shared-ui";
import { motion } from "framer-motion";
import { ThemeSliceState } from "../../store/store.types";

const Bundles = ({ data, selectBundle, index }: BundlesProps) => {
  const [timeLeft, setTimeLeft] = useState<string | boolean>("");
  const [bundleComponent, setBundleComponent] = useState<React.ReactElement>();
  const bundleDesign = useSelector((state: any) => state.design.bundles);
  const storeTheme = useSelector(
    ({ theme }: { theme: ThemeSliceState }) => theme.storeScreen
  );
  const generalTheme = useSelector(
    ({ theme }: { theme: ThemeSliceState }) => theme.general
  );
  const MILISECONDS_PER_MINUTE = 1000 * 60;
  const MILISECONDS_PER_HOUR = MILISECONDS_PER_MINUTE * 60;
  const MILISECONDS_PER_DAY = MILISECONDS_PER_HOUR * 24;

  useEffect(() => {
    if (data.endTime) {
      const timeDelta = new Date(data.endTime).getTime() - new Date().getTime();
      if (timeDelta > MILISECONDS_PER_DAY) {
        const timeLeftInDays = Math.ceil(timeDelta / MILISECONDS_PER_DAY);
        setTimeLeft(`${timeLeftInDays} days`);
      } else if (timeDelta > MILISECONDS_PER_HOUR) {
        const timeLeftInHours = Math.ceil(timeDelta / MILISECONDS_PER_HOUR);
        setTimeLeft(`${timeLeftInHours} hours`);
      } else if (timeDelta > 0) {
        const timeLeftInHours = Math.ceil(timeDelta / MILISECONDS_PER_MINUTE);
        setTimeLeft(`${timeLeftInHours} minutes`);
      } else {
        setTimeLeft(false);
      }
    }
  }, [
    MILISECONDS_PER_MINUTE,
    MILISECONDS_PER_HOUR,
    MILISECONDS_PER_DAY,
    data.endTime,
  ]);

  useEffect(() => {
    if (timeLeft === false) return;

    const setBundleDesign = async () => {
      setBundleComponent(
        <motion.div
          className='bundle-top-container'
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: index - index * 0.86 }}
          style={{ marginBottom: -16 }}
        >
          <motion.div
            style={{ zIndex: 1, position: "relative", cursor: "pointer" }}
            whileHover={{ scale: 1.1, zIndex: 100 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => selectBundle(data)}
          >
            <Bundle
              productList={data.productSetId.productList.map((p: any) => {
                return {
                  color: p.productId.textFontColorHex,
                  img: p.productId.images[0],
                  amount: p.amount,
                };
              })}
              width={380}
              height={180}
              bundleBorderColor={{
                gradientDirection:
                  storeTheme.bundleBorderColor.gradientDirection,
                colorOne: storeTheme.bundleBorderColor.colorOne,
                colorTwo: storeTheme.bundleBorderColor.colorTwo,
              }}
              bundleButtonColor={generalTheme.buttonColor.colorOne}
              bundleButtonTextColor={generalTheme.buttonTextColor}
              backgroundSrc={data.backgroundImage}
            />
          </motion.div>
        </motion.div>
      );
    };
    setBundleDesign();
  }, [bundleDesign, timeLeft, data, index, selectBundle]);

  return <>{bundleComponent}</>;
};

export default Bundles;
