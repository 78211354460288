import { colorPrefix } from '../../utils';
import { OrderSetProps, OrderSetProduct } from './order-set.types';
import Typography from '@mui/material/Typography';
import "./style.scss";

const OrderSet = ({products}: OrderSetProps) => {

  return (
    <div id="orderSet">      
      <div className="products">
        {
          products.map((product: OrderSetProduct, i: number) => {
            return(
              <div className="product" key={i}>
                <img alt="progress bar" src={product.productId.images[0]} />
                <Typography variant='body2' component="span" style={{color: colorPrefix(product.productId.textFontColorHex)}}>{product.amount}</Typography>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default OrderSet;
