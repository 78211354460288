import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSliceState } from './store.types';

const initialUserSliceState: UserSliceState = {
    facebookId: "",
    profilePicture: "",
    braintreeId: ""
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserSliceState,
    reducers: {
        login(state: UserSliceState, action:PayloadAction<Partial<UserSliceState>>) {
            state.facebookId = action.payload.facebookId!;
            state.profilePicture = action.payload.profilePicture!;
        },
        setBraintreeId(state: UserSliceState, action: PayloadAction<Partial<UserSliceState>>) {
            state.braintreeId = action.payload.braintreeId!;
        },
        logout(state) {
            state = initialUserSliceState;
        }
    }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
