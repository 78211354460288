import { useQuery } from "react-query";
import axios from "axios";
import { EQueryKeys } from "../../../constants/query-keys";

export default function useBundle(bundleId: string) {
  return useQuery({
    queryKey: [EQueryKeys.BUNDLE, bundleId],
    queryFn: async () => {
      if (!bundleId) return;
      return await axios.get(`store/bundles/${bundleId}`).catch((err) => {
        throw err;
      });
    },
  });
}
