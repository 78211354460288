import { useState } from 'react';
import { motion } from 'framer-motion';
import { ModalProps } from './Modal.types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'; 
import Typography from '@mui/material/Typography';
import './style.scss';

const Modal = ({closePopup, children, title, subtitle}: ModalProps) => {
    const [animationClass, setAnimationClass] = useState("");

    const closePopupHandler = () => {
        setAnimationClass("fadeout");
    }

    const onAnimationEnd = (e: React.AnimationEvent<HTMLElement>) => {
        if(e.animationName === "hideLayout") closePopup();
    }

    return (
        <div className={`overlay ${animationClass}`} onAnimationEnd={onAnimationEnd}>
            <motion.div animate={{scale: [0,1], rotate: [12,0]}} transition={{duration: 0.5}} id="Modal" className={animationClass}>
                <CloseOutlinedIcon onClick={closePopupHandler} className='closeButton'/>
                <Typography variant='h2'>{title}</Typography>
                <Typography variant='h5'>{subtitle}</Typography>
                {children}
            </motion.div>
        </div>
    );
};

export default Modal;
