import { ButtonProps } from './green-button.types';
import Typography from '@mui/material/Typography';
import './style.scss';

const GreenButton = ({text, action}: ButtonProps) => {
  return (
    <button className='greenButton' onClick={action}><Typography variant='button'>{text}</Typography></button>
  );
};

export default GreenButton;
