import { ELocalStorageKeys } from "./constants/enums";

export const colorPrefix = (color: string): string => {
  if (color[0] === "#") return color;
  return `#${color}`;
};

export const localStorageUtil = {
  set: (key: ELocalStorageKeys, value: any, isSession = false) => {
    (window as any)[isSession ? "sessionStorage" : "localStorage"].setItem(
      key,
      JSON.stringify(value)
    );
  },
  getAny: (key: ELocalStorageKeys) => {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key)!)
      : window.sessionStorage.getItem(key)
      ? JSON.parse(window.sessionStorage.getItem(key)!)
      : null;
  },
  get: (key: ELocalStorageKeys, isSession = false) => {
    return (
      (window as any)[isSession ? "sessionStorage" : "localStorage"].getItem(
        key
      ) &&
      JSON.parse(
        window[isSession ? "sessionStorage" : "localStorage"].getItem(key)!
      )
    );
  },
  remove: (key: ELocalStorageKeys, isSession = false) => {
    return (window as any)[
      isSession ? "sessionStorage" : "localStorage"
    ].removeItem(key);
  },
};

export const priceUtil = {
  formatPrice: (amount: number, currency = "USD") => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    })
      .format(amount)
      .replace(/\D00(?=\D*$)/, "");
  },
};
