import { useQuery } from "react-query";
import axios from "axios";
import { EQueryKeys } from "../../../constants/query-keys";

export default function useOrder(orderId: string) {
  // TODO REMOVE TIMEOUT

  return useQuery({
    queryKey: EQueryKeys.ORDER,
    queryFn: async () => {
      return await axios
        .get(`store/orders/${orderId}`, { timeout: 5000 })
        .catch((err) => {
          throw err;
        });
    },
  });
}
