import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Background } from "@appcharge/shared-ui";
import Bundles from "../../components/Bundles/Bundles";
import useAxios from "../../hooks/useAxios";
import useBundles from "./hooks/useBundles";
import usePaypal from "./hooks/usePaypal";
import { useSelector } from "react-redux";
import { ThemeSliceState } from "../../store/store.types";
import LogoutModal from "../../components/LogoutModal/LogoutModal";
import { PaymentModal } from "@appcharge/shared-ui";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/user-slice";
import braintree from "braintree-web";
import { Bundle } from "../../components/Bundles/Bundles.types";
import Spinner from "../../components/Spinner/Spinner";
import { ELocalStorageKeys } from "../../constants/enums";
import { localStorageUtil } from "../../utils";
import useUrlQuery from "../../hooks/useUrlQuery";
import "./style.scss";
import Modal from "../../components/Modal/Modal";
import GreenButton from "../../components/green-button/green-button";
import { ShopPageProps } from "./shop.types";

const ShopPage = ({ logout }: ShopPageProps) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const utmSource = useUrlQuery("utm_source");
  const dispatch = useDispatch();
  const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
  const facebookId = useSelector((state: any) => state.user.facebookId);
  const [paypal, setPaypalInstance] = useState<braintree.PayPal>();
  const braintreeId = usePaypal(paypal);
  const { isLoading, isError, data, error } = useBundles() as {
    isLoading: boolean;
    isError: boolean;
    data: any;
    error: any;
  };
  const [selectedBundle, setSelectedBundle] = useState<Bundle>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [processingPaypal, setProcessingPaypal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isLocalError, setIsLocalError] = useState<boolean | string>(false);
  const [bundlesList, setBundlesList] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const selectBundle = (bundleData: Bundle) => {
    setSelectedBundle(bundleData);
    setShowPaymentModal(true);
  };

  const closePopup = () => {
    setShowPaymentModal(false);
    setShowLogoutModal(false);
    setIsLocalError(false);
  };

  const checkoutWithCard = async (currency: string) => {
    setProcessing(true);
    closePopup();
    try {
      const response = await axios.post("/store/orders", {
        data: {
          offersetId: selectedBundle!._id, //todo: change to bundle after backend fix
          clientFbId: facebookId,
          clientGaId: localStorage.getItem("ga_client_id") || "2134654321",
          currency,
          paymentProvider: "stripe",
          utmSource,
        } as any,
      } as any);
      setProcessing(false);
      window.location.href = response.data.paymentUrl;
    } catch (e) {
      setProcessing(false);
      setIsLocalError("There was a problem with the payment process");
    }
  };

  const paypalClicked = () => {
    setProcessingPaypal(true);
    paypal!.tokenize(
      {
        flow: "vault",
        amount: selectedBundle!.price,
        currency: "USD",
      },
      async function (tokenizeErr, payload) {
        if (tokenizeErr) {
          setProcessingPaypal(false);
          if (tokenizeErr.type !== "CUSTOMER") {
            console.error("Error tokenizing:", tokenizeErr);
          }
          return;
        }
        const paymentResponse = await axios.post("/store/orders", {
          data: {
            offersetId: selectedBundle!._id, //todo: change to bundle after backend fix
            clientFbId: facebookId,
            clientGaId: localStorage.getItem("ga_client_id") || "2134654321",
            currency: "usd",
            paymentNonce: payload!.nonce,
            paymentProvider: "paypal",
            utmSource,
          },
        } as any);
        setProcessingPaypal(false);
        if (paymentResponse.status === 201)
          navigate(`./checkout/${paymentResponse.data._id}`);
      }
    );
  };

  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const loadMoreBundles = () => {
    setBundlesList(
      data.bundles.map((bundle: Bundle, i: number) => (
        <Bundles
          index={i}
          key={bundle._id}
          data={bundle}
          selectBundle={selectBundle}
        />
      ))
    );
    setShowLoadMoreButton(false);
  };

  useEffect(() => {
    if (
      isError &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      localStorageUtil.remove(ELocalStorageKeys.FB_ID);
      localStorageUtil.remove(ELocalStorageKeys.FB_PROFILE_PIC);
      navigate(`/login${utmSource ? `?utm_source=${utmSource}` : ""}`);
    } else if (isError) navigate("./failed");
  }, [isError, error?.response?.status, navigate, utmSource]);

  useEffect(() => {
    if (!braintreeId.data) return;

    braintree.client.create(
      {
        authorization: braintreeId.data,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }

        braintree.paypal.create(
          {
            client: clientInstance,
          },
          function (paypalErr, paypalInstance) {
            if (paypalErr) {
              console.error("Error creating PayPal:", paypalErr);
              return;
            }
            setPaypalInstance(paypalInstance);
          }
        );
      }
    );
  }, [braintreeId.data]);

  useEffect(() => {
    if (!data) return;
    axios
      .post("/auth/store/keepalive", { data: "", headers: "" })
      .then((response) => {
        dispatch(
          userActions.login({
            facebookId: response.data.clientFbId,
            profilePicture: response.data.fbProfileImageUrl,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        logout();
      });
  }, [data, axios, dispatch, logout]);

  useEffect(() => {
    if (!data) return;
    if (data.numbersOfBundlesToDisplayDesktop) {
      const threshold =
        window.innerWidth > 768
          ? data.numbersOfBundlesToDisplayDesktop
          : data.numbersOfBundlesToDisplayMobile;
      setBundlesList(
        data.bundles
          .slice(0, threshold)
          .map((bundle: Bundle, i: number) => (
            <Bundles
              index={i}
              key={bundle._id}
              data={bundle}
              selectBundle={selectBundle}
            />
          ))
      );

      if (data.bundles.length > threshold) setShowLoadMoreButton(true);
    } else {
      setBundlesList(
        data.map((bundle: Bundle, i: number) => (
          <Bundles
            index={i}
            key={bundle._id}
            data={bundle}
            selectBundle={selectBundle}
          />
        ))
      );
    }
  }, [data, setBundlesList]);

  return (
    <>
      {processing && <Spinner />}
      <Background
        backgroundImageMobile={theme.general.backgroundImageMobile}
        backgroundImageDesktop={theme.general.backgroundImageDesktop}
      />
      <Header openLogoutModal={openLogoutModal} />
      {isLoading ? (
        ""
      ) : (
        <div id='shop'>
          <div className='container'>
            <div className='bundlesContainer'>{bundlesList}</div>
            {showLoadMoreButton && (
              <GreenButton text='Load more' action={loadMoreBundles} />
            )}
          </div>
          <Footer links={true}/>
        </div>
      )}
      {showPaymentModal && (
        <PaymentModal
          fontFamily={theme.general.font}
          headerText={theme.paymentModal.headerText}
          headerColor={theme.paymentModal.headerColor}
          headerSize={theme.paymentModal.headerSize}
          text={theme.paymentModal.text || "how would you like to pay?"}
          textColor={theme.paymentModal.textColor}
          textSize={theme.paymentModal.textSize}
          modalColor={theme.paymentModal.popupColor}
          modalBorderColor={theme.paymentModal.popupBorderColor}
          checkoutWithCard={checkoutWithCard}
          closeModal={closePopup}
          paypalClicked={paypalClicked}
          processingPaypal={processingPaypal}
          buttonColor={theme.general.buttonColor}
          preview={false}
        />
      )}
      {showLogoutModal && (
        <LogoutModal closePopup={closePopup} logout={logout} />
      )}
      {isLocalError && (
        <Modal
          closePopup={closePopup}
          title='Oh no!'
          subtitle={isLocalError as string}
        >
          <GreenButton text={"Try again"} action={closePopup} />
        </Modal>
      )}
    </>
  );
};

export default ShopPage;
