import {
  MessagePage,
  ActionButton,
  Background,
  Checkout,
  // Loader
} from "@appcharge/shared-ui";
import useOrder from "./hooks/useOrder";
import { CircularProgress } from "@mui/material";
import useBundle from "./hooks/useBundle";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import confetti from "../../assets/animations/confetti.json";
import OrderSet from "../../components/order-set/order-set";
import { useSelector } from "react-redux";
import { ThemeSliceState } from "../../store/store.types";
import "./style.scss";
import { useEffect, useState } from "react";

const CheckoutPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const order = useOrder(orderId!);
  const theme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme);
  const [animationDone, setAnimationDone] = useState(false);
  const bundle = useBundle(order.data?.data.offersetId || ""); //todo: change to bundle after backend fix
  const [page, setPage] =
    useState<"completedScreen" | "loaderScreen">("loaderScreen");
  if (
    order.isError ||
    bundle.isError ||
    order?.data?.data?.state === "charge_failed"
  )
    navigate("../failed?error=payment");

  useEffect(() => {
    if (!bundle.data || !order.data) return;
    setPage("completedScreen");
  }, [bundle.data, order.data]);

  const returnToGame = () => {
    navigate("../");
  };

  return (
    <Background
      backgroundImageDesktop={theme.general.backgroundImageDesktop}
      backgroundImageMobile={theme.general.backgroundImageMobile}
    >
      <MessagePage
        headerText={theme[page].headerText}
        headerColor={theme[page].headerColor}
        headerSize={theme[page].headerSize}
        text={theme[page].text}
        textColor={theme[page].textColor}
        textSize={theme[page].textSize}
        fontFamily={theme.general.font}
      >
        <Checkout>
          {/* <Loader
            process={order.isLoading || bundle.isLoading}
            images={[
              "https://media.appcharge.com/defaults/apple_pay.svg",
              "https://media.appcharge.com/defaults/google_pay.svg",
            ]}
          /> */}
          {
            (order.isLoading || bundle.isLoading) && <CircularProgress />
          }
          {bundle.data && bundle.data.data && (
            <>
              {!animationDone && (
                <Lottie
                  className='coinsAnimation'
                  animationData={confetti}
                  loop={false}
                  onComplete={() => setAnimationDone(true)}
                  style={{
                    zIndex: "-1",
                    transform: "scale(0.5)",
                    top: "-50vh",
                  }}
                />
              )}
              <OrderSet products={bundle.data.data.productSetId.productList} />
              <ActionButton
                text={theme.completedScreen.backToStoreText}
                action={returnToGame}
                colors={theme.general.buttonColor}
              />
            </>
          )}
        </Checkout>
      </MessagePage>
    </Background>
  );
};

export default CheckoutPage;
