import { InfoModalProps } from './InfoModal.types';
import Typography from '@mui/material/Typography';
import './style.scss';

const InfoModal = ({closeModal, children, title}: InfoModalProps) => {

    return (
        <div className='transparentOverlay'>
            <div className='infoModal'>
                <span onClick={closeModal}>X</span>
                <Typography variant='h3'>{title}</Typography>
                {children}
            </div>
        </div>
    );
};

export default InfoModal;
