import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/login/login';
import ShopPage from './pages/shop/shop';
import Maintenance from './pages/maintenance/maintenance';
import Checkout from './pages/checkout/checkout';
import Failed from './pages/failed/failed';
import { QueryClient, QueryClientProvider } from 'react-query';
import LogRocket from 'logrocket';
import { useDispatch } from "react-redux";
import { themeActions } from "./store/theme-slice";
import { integrationActions } from './store/integration-slice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { localStorageUtil } from "./utils";
import { ELocalStorageKeys } from "./constants/enums";
import { themes, ETheme } from './themes/theme';
import { useNavigate } from 'react-router-dom';
import useAxios from './hooks/useAxios';
import useAnalytics from './hooks/useAnalytics';
import { useEffect, useState } from 'react';
LogRocket.init('yokxf1/appcharge');

const theme = createTheme(themes[ETheme.LIGHT]);

function App() {
  const dispatch = useDispatch();
  const axios = useAxios();
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const analytics = useAnalytics();
  analytics.init();
  const [themeLoaded, setThemeLoaded] = useState(false);

  const logout = async () => {
    localStorageUtil.remove(ELocalStorageKeys.FB_ID);
    const response = await axios.post("/auth/store/logout", {
      data: {},
      headers: {},
    });
    if (response.data === "ok") navigate("./login");
  };

  useEffect(() => {
    const getPublisherTheme = async () => {
      const response = await axios.get(`/store/boot/${window.location.host}`);
      setThemeLoaded(true);
      dispatch(
        themeActions.setTheme({
            login: response.storeTheme.login,
            general: response.storeTheme.general,
            storeScreen: response.storeTheme.storeScreen,
            paymentModal: response.storeTheme.paymentScreen,
            loaderScreen: response.storeTheme.loaderScreen,
            completedScreen: response.storeTheme.completedScreen
        })
      );
      dispatch(
        integrationActions.setupIntegration({
          fbAppId: response.integration.fbAppId,
          backToGameDeepLink: response.integration.backToGameDeepLink,
          publisherId: response.storeTheme.publisherId
        })
      )
    }
    getPublisherTheme();
  }, [dispatch, axios])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {
          themeLoaded &&
          <Routes>
            <Route 
              path="/login"
              element={<LoginPage initAnalytics={analytics.init}/>}
            />
            <Route 
              path="/"
              element={
                <ShopPage logout={logout} />
              }
            />
            <Route
              path="/maintenance" 
              element={
                <Maintenance />
              }
            />
            <Route
              path="/checkout/:orderId" 
              element={
                <Checkout />
              }
            />
            <Route
              path="/failed" 
              element={
                <Failed />
              }
            />
            <Route
              path="*"
              element={
                <Navigate to="/" replace />
              }
            />
          </Routes>
        }
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
