import ReactGA from "react-ga4";
import { CONF } from "../conf";
import { useState } from "react";

export default function useAnalytics(){
    const [initionlized, setInitionlized] = useState(false);
    
    const init = () => {
        if (!JSON.parse(localStorage.getItem("analytics") as string) || initionlized) return;
        setInitionlized(true);
        ReactGA.initialize((CONF as any)[process.env.REACT_APP_ENV!].googleAnalytics as string, {
          testMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        });
        ReactGA.ga((tracker: any) => {
          localStorage.setItem("ga_client_id", tracker.get("clientId"));
        });
    }
    return {init}
}
