import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSliceState } from '../../store/store.types';
import { HeaderProps } from './Header.types';
import { ThemeSliceState } from "../../store/store.types";
import './style.scss';

const Header = ({openLogoutModal}: HeaderProps) => {
  const pic = useSelector(({ user }: { user: UserSliceState }) => user.profilePicture)
  const generalTheme = useSelector(({ theme }: { theme: ThemeSliceState }) => theme.general);
  const [picLoaded, setPicLoaded] = useState(false);
  
  return (
    <div id="header">
      <img alt="logo" className='logo' src={generalTheme.logo}/>
      {
        pic &&
        <div className='profileContainer' style={{visibility: picLoaded ? "visible" : "hidden",backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/bundle_frame.webp)`}}>
          <img alt="profile" src={pic} className='profile' onClick={openLogoutModal} onLoad={() => setPicLoaded(true)}/>
        </div>
      }
    </div>
  );
};

export default Header;
